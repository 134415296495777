const binding = { modules: {}, dataActions: {} };

    (binding.modules['image-list'] = {
        c: () => require('@msdyn365-commerce-modules/image-list/dist/lib/modules/image-list/image-list'),
        $type: 'contentModule',
        da: [],
        
        iNM: true,
        ns: '@msdyn365-commerce-modules',
        n: 'image-list',
        p: 'image-list',
        
        pdp: '',
        
        
        md: 'node_modules/@msdyn365-commerce-modules/image-list/dist/lib/modules/image-list'
    });
        

        
    window.__bindings__ = window.__bindings__ || {};
    window.__bindings__.modules = {
        ...window.__bindings__.modules || {},
        ...binding.modules
    };
    
        window.__bindings__.dataActions = {
        ...window.__bindings__.dataActions || {},
        ...binding.dataActions
    };
                        export const viewDictionary = {};
                        viewDictionary['__local__|__local__|themes|hobart|views|image-list'] = {
                c: () => require('partner/themes/hobart/views/image-list.view.tsx'),
                cn: '__local__-__local__-image-list'
            };
viewDictionary['@msdyn365-commerce-modules|image-list|modules|image-list|image-list'] = {
                c: () => require('@msdyn365-commerce-modules/image-list/dist/lib/modules/image-list/image-list.view.js'),
                cn: '@msdyn365-commerce-modules-image-list-image-list'
            };
window.__bindings__ = window.__bindings__ || {};
window.__bindings__.viewDictionary = {
                        ...window.__bindings__.viewDictionary || {},
                        ...viewDictionary
                    };